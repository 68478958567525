import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/weare',
        name: 'weare',
        component: () => import(/* webpackChunkName: "about" */ '../views/Weare.vue')
    },
    {
        path: '/ocean_spray',
        name: 'ocean_spray',
        component: () => import(/* webpackChunkName: "about" */ '../views/Ocean.vue')
    },
    {
        path: '/self_services',
        name: 'self_services',
        component: () => import(/* webpackChunkName: "about" */ '../views/SelfServices.vue')
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
    },
    {
        path: '/products',
        name: 'products',
        component: () => import(/* webpackChunkName: "about" */ '../views/Products.vue')
    },
    {
        path: '/commitment',
        name: 'commitment',
        component: () => import(/* webpackChunkName: "about" */ '../views/Commitment.vue')
    },
    {
        path: '/infrastructure',
        name: 'infrastructure',
        component: () => import(/* webpackChunkName: "about" */ '../views/Infrastructure.vue')
    },
    

    // CATEGORIES
    {
        path: '/category_nuts',
        name: 'category_nuts',
        component: () => import(/* webpackChunkName: "about" */ '../views/categories/CategoryNuts.vue')
    },
    {
        path: '/category_super',
        name: 'category_super',
        component: () => import(/* webpackChunkName: "about" */ '../views/categories/CategorySuper.vue')
    },
    {
        path: '/category_dehydrated',
        name: 'category_dehydrated',
        component: () => import(/* webpackChunkName: "about" */ '../views/categories/CategoryDehydrated.vue')
    },
    {
        path: '/category_jamaica',
        name: 'category_jamaica',
        component: () => import(/* webpackChunkName: "about" */ '../views/categories/CategoryJamaica.vue')
    },
    {
        path: '/category_hot',
        name: 'category_hot',
        component: () => import(/* webpackChunkName: "about" */ '../views/categories/CategoryHot.vue')
    },
    {
        path: '/category_spices',
        name: 'category_spices',
        component: () => import(/* webpackChunkName: "about" */ '../views/categories/CategorySpices.vue')
    },


    // PRODUCTS
    {
        path: '/product_almond',
        name: 'product_almond',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductAlmond.vue')
    },
    {
        path: '/product_pistachio',
        name: 'product_pistachio',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductPistachio.vue')
    },
    {
        path: '/product_walnut',
        name: 'product_walnut',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductWalnut.vue')
    },
    {
        path: '/product_western',
        name: 'product_western',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductWestern.vue')
    },
    {
        path: '/product_jamaica',
        name: 'product_jamaica',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductJamaica.vue')
    },
    {
        path: '/product_cranberry',
        name: 'product_cranberry',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductBlueberries.vue')
    },
    {
        path: '/product_cherry',
        name: 'product_cherry',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductCherry.vue')
    },
    {
        path: '/product_plum',
        name: 'product_plum',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductPlum.vue')
    },
    {
        path: '/product_coconut',
        name: 'product_coconut',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductCoconut.vue')
    },
    {
        path: '/product_peach',
        name: 'product_peach',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductPeach.vue')
    },
    {
        path: '/product_apricot',
        name: 'product_apricot',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductApricot.vue')
    },
    {
        path: '/product_fruit',
        name: 'product_fruit',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductFruit.vue')
    },
    {
        path: '/product_kiwi',
        name: 'product_kiwi',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductKiwi.vue')
    },
    {
        path: '/product_mango',
        name: 'product_mango',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductMango.vue')
    },
    {
        path: '/product_apple',
        name: 'product_apple',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductApple.vue')
    },
    {
        path: '/product_pineapple',
        name: 'product_pineapple',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductPineapple.vue')
    },
    {
        path: '/product_raisin',
        name: 'product_raisin',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductRaisin.vue')
    },
    {
        path: '/product_datil',
        name: 'product_datil',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductDatil.vue')
    },
    {
        path: '/product_chia',
        name: 'product_chia',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductChia.vue')
    },
    {
        path: '/product_quinoa',
        name: 'product_quinoa',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductQuinoa.vue')
    },
    {
        path: '/product_cinnamon',
        name: 'product_cinnamon',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductCinnamon.vue')
    },
    {
        path: '/product_clove',
        name: 'product_clove',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductClove.vue')
    },
    {
        path: '/product_cumin',
        name: 'product_cumin',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductCumin.vue')
    },
    {
        path: '/product_pepper',
        name: 'product_pepper',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductPepper.vue')
    },
    {
        path: '/product_cranberry-hot',
        name: 'product_cranberry-hot',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductBlueberries-hot.vue')
    },
    {
        path: '/product_gomimango',
        name: 'product_gomimango',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductGomimango.vue')
    },
    {
        path: '/product_mango-hot',
        name: 'product_mango-hot',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductMango-hot.vue')
    },
    {
        path: '/product_pineapple-hot',
        name: 'product_pineapple-hot',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductPineapple-hot.vue')
    },
    {
        path: '/product_flax',
        name: 'product_flax',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductFlax.vue')
    },
    {
        path: '/product_pumpkin_seeds',
        name: 'product_pumpkin_seeds',
        component: () => import(/* webpackChunkName: "about" */ '../views/products/ProductPumpkinSeeds.vue')
    }    
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


router.beforeEach((to, from, next) => {
    //console.log(to, 'hacia')
    //console.log(from, 'desde')
    next()
})

export default router
