<template>
    <div id="pr-footer" class="container-fluid">
        <!-- Content -->
        <section class="row text-left p-5">
            <!-- Information -->
            <div class="col-12 col-lg-4 px-0 px-sm-5">
                <div style="max-width: 350px;">
                    <img :src="gif_premier_mixed" class="img-fluid mb-4 pr-5">    
                </div>
                <div class="py-2">
                    <span class="pr-description-sm">Paseo del Pacifico #645</span>
                </div>
                <div class="py-2">
                    <span class="pr-description-sm">Parque industrial Technology Park</span>
                </div>
                <div class="py-2">
                    <span class="pr-description-sm">Zapopan, Jalisco, México</span>
                </div>
                <div class="py-2">
                    <span class="pr-description-sm">+52 (33) 8000 8300</span>
                </div>
                <div class="py-2">
                    <span class="pr-description-sm">info@gpopremier.com</span>
                </div>
            </div>

            <!-- Menú -->
            <div class="col-6 col-sm-6 col-lg-3 d-flex align-items-start px-0 px-sm-5 py-5 py-lg-0">
                <div>
                    <div class="py-2">
                        <span class="db-description-sm poppins-bold">MENÚ</span>
                    </div>
                    <div class="py-2">
                        <span @click="redirect('home')" class="span-link db-description-sm">INICIO</span>
                    </div>
                    <div class="py-2">
                        <span @click="redirect('weare')" class="span-link db-description-sm">SOMOS</span>
                    </div>
                    <div class="py-2">
                        <span @click="redirect('commitment')" class="span-link db-description-sm">COMPROMISO</span>
                    </div>
                    <div class="py-2">
                        <span @click="redirect('products')" class="span-link db-description-sm">PRODUCTO</span>
                    </div>
                    <div class="py-2">
                        <span @click="redirect('ocean_spray')" class="span-link db-description-sm">OCEAN SPRAY</span>
                    </div>
                    <div class="py-2">
                        <span @click="redirect('contact')" class="span-link db-description-sm">CONTACTO</span>
                    </div>
                </div>
            </div>

            <!-- Legal -->
            <div class="col-6 col-sm-6 col-lg-3 d-flex align-items-start px-0 px-sm-5 py-5 py-lg-0">
                <div>
                    <div class="py-2">
                        <span class="db-description-sm poppins-bold">LEGAL</span>
                    </div>
                    <div class="py-2">
                        <span @click="redirect('privacy')" class="span-link db-description-sm">AVISO DE PRIVACIDAD</span>
                    </div>
                    <div class="py-2">
                        <span @click="redirect('terms')" class="span-link db-description-sm">TÉRMINOS Y CONDICIONES</span>
                    </div>
                    <div class="py-2">
                        <span @click="redirect('cookies')" class="span-link db-description-sm">POLÍTICA DE COOKIES</span>
                    </div>
                    <div class="py-2">
                        <span @click="redirect('#')" class="span-link db-description-sm">SOPORTE</span>
                    </div>
                </div>
            </div>

            <!-- Social Media && Brands -->
            <div class="col-12 col-lg-2 d-flex justify-content-start align-items-start px-0 px-sm-5">
                <div>
                    <div class="">
                        <span class="db-description-sm poppins-bold">SÍGUENOS</span>
                    </div>
                    <div class="pt-4">
                        <a href="https://www.facebook.com/premierdeoccidente" target="_blank"><img :src="img_fb" class="mx-1"></a>
                        <a href="https://twitter.com/PremierGrupo" target="_blank"><img :src="img_tw" class="mx-1"></a>
                        <a href="https://www.instagram.com/gpopremier" target="_blank"><img :src="img_in" class="mx-1"></a>
                    </div>

                    <div class="mt-5">
                        <span class="db-description-sm poppins-bold">NUESTRAS MARCAS</span>
                    </div>
                    <div class="pt-3">
                        <a href="http://premiernuts.mx/" target="_blank">
                            <img :src="img_nuts" class="img-fluid m-2">
                        </a>
                        <a @click="redirect('ocean_spray')" target="_blank">
                            <img :src="img_spray" class="img-fluid m-2">
                        </a>
                        <a href="https://mercadopremier.com/" target="_blank">
                            <img :src="img_mercado" class="img-fluid m-2">
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section class="row d-flex justify-content-end" style="color: #555; background-color: white;">
            <div class="col-12 col-sm-6 col-lg-8 d-flex justify-content-start align-items-center pl-3 pl-sm-5 py-2 py-sm-4 py-lg-0">
                © 2023 Grupo Premier. Todos los derechos reservados
            </div>
            <div class="col-8 col-sm-6 col-lg-4 px-0 px-sm-5 pt-sm-4 pt-md-3 pt-lg-0 px-xl-5">
                <a href="https://blueberry.mx/" target="_blank" rel="noopener noreferrer">
                    <img :src="gif_materialized" class="img-fluid">
                </a>
                
            </div>
        </section>
    </div>
</template>

<script>
    // Images
    import img_fb from '../assets/images/navbar/facebook.png'
    import img_tw from '../assets/images/navbar/twitter.png'
    import img_in from '../assets/images/navbar/instagram.png'

    import img_nuts from '../assets/images/footer/premier-nuts.png'
    import img_spray from '../assets/images/footer/ocean-spray.png'
    import img_mercado from '../assets/images/footer/mercado-premier.png'
    // Gifs
    import gif_premier_color from '../assets/images/home/logo-premier-colores.png'
    import gif_premier_white from '../assets/images/home/logo-premier-blanco.png'
    import gif_premier_mixed from '../assets/images/home/logo-premier.png'
    import gif_materialized from '../assets/gif/we-are-Bb_black.gif'
    

    export default {
        name: 'pr-footer',
        props: {
            msg: String
        },
        data() {
            return {
                img_fb,
                img_tw,
                img_in,
                img_nuts, 
                img_spray, 
                img_mercado, 

                gif_premier_color,
                gif_premier_white,
                gif_premier_mixed,
                gif_materialized
            }
        },
        methods: {
            redirect(name) {
                window.scrollTo( 0, 0 )
                
                this.$router.push({
                    name: name
                })
            }
        },
    }
</script>

<style scoped lang="scss">
    #pr-footer {
        background-color: #002654;
        color: white;
        
        .span-link {
            color: #eee !important;
        }
        .span-link:hover {
            text-decoration: underline;
            text-decoration-style: double;
        }
    }
</style>
