<template>
  	<div id="pr_home" class="container-fluid">
  		<!-- video main -->
		<section id="pr_video_main" class="section__index d-flex">
            <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                <source :src="video_main" type="video/mp4">
            </video>
            <div id="overlay_main_video" data-aos="fade-up">
                <div class="overlay_main_video--text mb-2 mb-sm-5 px-0 px-md-2 px-lg-4">
                    <div class="px-2 px-md-3 px-lg-4">
                        <span class="pr--title poppins-bold text-white">LA EMPRESA #1 EN MÉXICO</span> <br>
                        <span class="pr--description text-white">En distribución de nueces y frutos secos.</span>
                    </div>
                </div>
            </div>
        </section>

        <section class="mb-5 d-none d-md-block" style="margin-top: -100px;">
            <div class="row d-flex justify-content-center align-items-center p-0">
                <div class="col-3 d-flex justify-content-center align-items-end" data-aos="fade-up" data-aos-delay="400">
                    <img :src="section1_img1" class="img-fluid">
                    <div class="pb-4 pb-lg-5" style="width: 100%; position: absolute;">
                        <div class="row">
                            <div class="col-12">
                                <center>
                                    <div style="max-width: 240px;">
                                        <span class="pr--description poppins-bold text-white">DESARROLLO HUMANO</span>    
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 d-flex justify-content-center align-items-end" data-aos="fade-up" data-aos-delay="500">
                    <img :src="section1_img2" class="img-fluid">
                    <div class="pb-4 pb-lg-5" style="width: 100%; position: absolute;">
                        <div class="row">
                            <div class="col-12">
                                <center>
                                    <div style="max-width: 250px;">
                                        <span class="pr--description poppins-bold text-white">PRODUCTOS DE CALIDAD MUNDIAL</span>    
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-3 d-flex justify-content-center align-items-end" data-aos="fade-up" data-aos-delay="600">
                    <img :src="section1_img3" class="img-fluid">
                    <div class="pb-4 pb-lg-5" style="width: 100%; position: absolute;">
                        <div class="row">
                            <div class="col-12">
                                <center>
                                    <div style="max-width: 250px;">
                                        <span class="pr--description poppins-bold text-white">GRAN CAPACIDAD INSTALADA</span>    
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- image section -->
        <section class="row">
        	<div class="col-12 d-flex justify-content-center align-items-center p-0">
                <img :src="section2_img1" class="img-fluid">

                <div data-aos="fade-up" style="width: 100%; position: absolute;">
                    <div class="row">
                        <div class="col-4 col-lg-6"></div>
                        <div class="col-8 col-lg-6 p-0 pl-lg-5">
                            <div class="p-0 pl-lg-5" style="max-width: 500px;">
                                <span class="pr--title text-white text-left ">
                                    50 AÑOS DE EXPERIENCIA EN LA PALMA DE TU MANO
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
        	</div>
        </section>

        <!-- col image informative -->
        <section class="row" v-if="false">
        	<div data-aos="fade-up" class="col-12 col-lg-6 d-flex justify-content-center align-items-center p-2">
                <div style="max-width: 450px;">
                    <img :src="section2_img2" class="img-fluid px-5">
                </div>
        	</div>
        	<div data-aos="fade-up" class="col-12 col-lg-6 pb-5">
        		<div class="row text-left px-0 px-md-5">
        			<div class="col-12 pt-5">
        				<div style="max-width: 600px;">
		        			<span class="pr--title poppins-bold">PRODUCTOS 100% <span class="text-success">CONFIABLES</span></span>	
		        		</div>
        			</div>
        			<div class="col-12 d-flex justify-content-center align-items-center text-justify pt-4 pt-xl-5">
        				<span class="pr--description">
        					En Grupo Premier cuidamos la capacidad en cada uno de nuestros procesos para obtener un producto con la más alta calidad.
        				</span>
        			</div>
        		</div>
        	</div>
        </section>

        <!-- image section -->
        <section class="row">
        	<div class="col-12 d-flex justify-content-start align-items-center p-0">
        		<img :src="section2_img3" class="img-fluid">

        		<div class="p-5" style="position: absolute;">
        			<div class="row">
        				<div class="col-6">
                            <center data-aos="fade-up">
                                <div style="max-width: 400px;">
                                    <img :src="gif_premier_mixed" class="img-fluid mb-4 mb-xl-5"> <br>
                                </div>    
                            </center>
	        				
	        				<span data-aos="fade-up" data-aos-delay="400" class="pr--description text-white d-none d-md-flex">
                                En Grupo Premier importamos frutos secos, nueces, almendras, cereales y semillas desde más de 25 países del mundo.
                            </span>
	        			</div>
        			</div>
        		</div>

        	</div>
        </section>

         <!-- col video informative -->
        <section id="success-story" class="row">
        	<div class="col-12 col-lg-6 d-flex justify-content-center align-items-center p-2">
                <div data-aos="zoom-in-down">
                    <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" class="video-fluid">
		                <source :src="s2_video1" type="video/mp4">
		            </video>
                </div>
        	</div>
        	<div class="col-12 col-lg-6 pb-5">
        		<div class="row text-left px-0 px-md-5">
        			<div data-aos="fade-left" class="col-12 pt-5">
		        		<span class="pr--description text-muted poppins-light">EXPERIENCIA</span>
        			</div>
        			<div data-aos="fade-left" class="col-12">
        				<div class="" style="max-width: 1000px;">
		        			<span class="pr--title poppins-black">UNA HISTORIA <br class="d-none d-md-flex"> DE ÉXITO</span>
		        		</div>
        			</div>
        			<div data-aos="fade-up" data-aos-delay="400" class="col-12 text-justify pt-4 pt-xl-4">
        				<div class="" style="max-width: 600px;">
        					<span class="pr--description">
                                Nuestros más de 50 años de experiencia nos han permitido crear una historia de éxito. <br><br>
                                Nos hemos consolidado como la empresa líder en importación, procesamiento y distribución de frutos secos en México.
	        				</span>	
        				</div>
        			</div>
        			<div data-aos="fade-up" data-aos-delay="400" class="col-12 d-flex justify-content-end align-items-end" style="min-height: 200px; max-width: 600px;">
        				<b-button @click="redirect('weare')" variant="primary" class="poppins-bold px-3 py-2">CONOCE MÁS</b-button>
        			</div>
        		</div>
        	</div>
        </section>

        <!-- products video section -->
        <section id="our-products" class="row pb-5">
        	<div class="col-12 d-flex justify-content-start align-items-end p-0">
        		<video id="s3_video_1" playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop" class="video-fluid">
	                <source :src="s3_video1" type="video/mp4">
	            </video>

                <div id="s3_div_cont_target_1" class="w-100 position-absolute">
                    <div id="s3_div_row_target_1" class="row d-flex justify-content-center align-items-end">

                        <div class="col-12">
                            <div data-aos="fade-up" class="my-2">
                                <span class="pr--title-md text-white">
                                    NUESTROS PRODUCTOS
                                </span>
                            </div>

                            <div data-aos="fade-up" data-aos-delay="400" class="d-none d-md-block mb-3">
                                <span class="pr--description text-white">
                                    Conoce nuestra amplia variedad de productos.
                                </span>
                            </div>

                            <div data-aos="fade-up" data-aos-delay="400" class="d-none d-xs-block mb-3">
                                <b-button @click="redirect('products')" variant="outline-light" class="pr-button px-4 py-3">
                                    <span class="pr--description poppins-bold text-white">CONOCE MÁS</span>
                                </b-button>
                            </div>
                        </div>

                        <div data-aos="fade-up" data-aos-delay="600" class="col-9 px-5">
                            <img :src="section3_img1" class="img-fluid mb-0 mb-xl-1">
                        </div>

                    </div>
                </div>
        	</div>
        </section>

        <!-- col image informative -->
        <section id="strong-relationship" class="row bg-secondary">
        	<div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                <div data-aos="zoom-in-down">
                    <img :src="section3_img2" class="img-fluid">
                </div>
        	</div>
        	<div class="col-12 col-lg-6 pb-5">
        		<div class="row h-100 d-flex justify-content-center align-content-around flex-wrap text-left px-0 px-md-5">
        			<div class="col-12 pt-5">
        				<div data-aos="fade-left" style="max-width: 600px;">
		        			<span class="pr--title poppins-bold">
                                SÓLIDA RELACIÓN <br class="d-none d-md-flex">
                                CON NUESTROS <br class="d-none d-md-flex">
                                SOCIOS
                            </span>	
		        		</div>

                        <div data-aos="fade-up" data-aos-delay="400" class="text-justify pt-4 pt-xl-5" style="max-width: 600px;">
                            <span class="pr--description">
                                La sólida y estrecha relación con nuestros socios alrededor del mundo se ha vuelto una pieza fundamental para el desarrollo de nuestra empresa, creando así nuestra gran Familia Premier.
                            </span>
                        </div>
        			</div>
        			
        			<div class="col-12">
                        <div data-aos="fade-up" data-aos-delay="400" class="d-flex justify-content-end" style="max-width: 600px;">
                            <b-button @click="redirect('weare')" variant="primary" class="poppins-bold  ">CONOCE MÁS</b-button>
                        </div>
        			</div>
        		</div>
        	</div>
        </section>

        <!-- col image informative -->
        <section class="row">
        	<div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                <div data-aos="zoom-in-down">
                    <img :src="section4_img1" class="img-fluid">
                </div>
        	</div>
        	<div class="col-12 col-lg-6 d-flex justify-content-center align-items-center">
        		<div class="row text-left px-0 px-md-5">
        			<div data-aos="fade-up" class="col-12 pt-5">
        				<div style="max-width: 600px;">
		        			<span class="pr--title poppins-bold">INOCUIDAD <span class="text-success">Y TECNOLOGÍA</span></span>	
		        		</div>
        			</div>
        			<div data-aos="fade-up" data-aos-delay="400" class="col-12 text-justify pt-4 pt-xl-5">
                        <div style="max-width: 400px;">
                            <span class="pr--description">
                                La inocuidad es un pilar fundamental en el desarrollo en nuestros productos.
                            </span>    
                        </div>
        			</div>
                    <div class="col-12 d-flex justify-content-end align-items-end mb-4" style="min-height: 60px; max-width: 600px;">
                        <!-- <b-button variant="primary" class="poppins-bold">CONOCE MÁS</b-button> -->
                    </div>
        		</div>
        	</div>
        </section>

        <!-- col images -->
        <section class="row d-flex justify-content-center align-items-start">
            <div data-aos="zoom-out-right" class="col-12 col-sm-6 col-lg-3 p-0 m-0">
                <img :src="section4_img2" class="img_black_white img-fluid p-0" style="margin-right: -2px !important;">
            </div>
            <div data-aos="zoom-out-right" class="col-12 col-sm-6 col-lg-3 p-0 m-0">
                <img :src="section4_img3" class="img_black_white img-fluid p-0" style="margin-right: -1px !important;">
            </div>
            <div data-aos="zoom-out-right" class="col-12 col-sm-6 col-lg-3 p-0 m-0">
                <img :src="section4_img4" class="img_black_white img-fluid p-0 m-0">
            </div>
            <div data-aos="zoom-out-right" class="col-12 col-sm-6 col-lg-3 p-0 m-0">
                <img :src="section4_img5" class="img_black_white img-fluid p-0 m-0">
            </div>            
        </section>

        <!-- line green -->
        <section class="row bg-success d-flex justify-content-center align-items-center py-5">
            <div>
                <img :src="img_logo_white" data-aos="fade-up" class="img-fluid mb-4 mb-xl-5"> <br>

                <div data-aos="fade-up" data-aos-delay="400" style="max-width: 800px;">
                    <span class="pr--description text-white">
                        En los últimos 50 años Grupo Premier nos hemos consolidado como la empresa líder en importación, procesamiento y distribución de frutos secos en México.
                    </span>
                </div>
            </div>
        </section>

        <section class="row" v-if="false">
            <div class="col-12 px-0">
                <img :src="section4_img6" class="img-fluid">    
            </div>
        </section>

        <section id="product-line" class="row p-5">
            <div data-aos="fade-up" class="col-12 d-flex justify-content-center pb-5">
                <div class="pb-5" style="max-width: 600px;">
                    <span class="pr--title poppins-black">CONOCE NUESTRA LÍNEA DE PRODUCTOS</span>    
                </div>
            </div>

            <div class="col-12 col-lg-6">
                <div class="row pt-5">
                    <div data-aos="fade-right" class="col-6 d-flex">
                        <img :src="section5_img1" class="img-fluid px-5">
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-start">
                        <div>
                            <div data-aos="fade-right" class="bg-success text-white poppins-black mt-4 mb-4" style="border-radius: 8px; padding: 8px;">
                                FRUTOS SECOS
                            </div>
                            <div data-aos="fade-right" data-aos-delay="400" class="text-left">
                                <span class="pr--description">Conoce nuestra amplia variedad de frutos secos.</span> <br>
                                <span @click="redirect('category_nuts')" class="pr--description poppins-bold text-success cursor-pointer">Conoce más ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row pt-5">
                    <div data-aos="fade-right" class="col-6">
                        <img :src="section5_img2" class="img-fluid">
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-start">
                        <div>
                            <div data-aos="fade-right" class="bg-success text-white poppins-black mt-4 mb-4" style="border-radius: 8px; padding: 8px;">
                                SUPER FOODS
                            </div>
                            <div data-aos="fade-right" data-aos-delay="400" class="text-left">
                                <span class="pr--description">Productos super foods para una mejor alimentación.</span> <br>
                                <span @click="redirect('category_super')" class="pr--description poppins-bold text-success cursor-pointer">Conoce más ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row pt-5">
                    <div data-aos="fade-right" class="col-6">
                        <img :src="section5_img3" class="img-fluid px-5">
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-start">
                        <div>
                            <div data-aos="fade-right" class="bg-success text-white poppins-black mt-4 mb-4" style="border-radius: 8px; padding: 8px;">
                                FRUTOS DESHIDRATADOS
                            </div>
                            <div data-aos="fade-right" data-aos-delay="400" class="text-left">
                                <span class="pr--description">Calidad y un excelente sabor en nuestros frutos deshidratados.</span> <br>
                                <span @click="redirect('category_dehydrated')" class="pr--description poppins-bold text-success cursor-pointer">Conoce más ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row pt-5">
                    <div data-aos="fade-right" class="col-6 px-5">
                        <img :src="section5_img4" class="img-fluid">
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-start">
                        <div>
                            <div data-aos="fade-right" class="bg-success text-white poppins-black mt-4 mb-4" style="border-radius: 8px; padding: 8px;">
                                JAMAICA
                            </div>
                            <div data-aos="fade-right" data-aos-delay="400" class="text-left">
                                <span class="pr--description">Nuestra Jamaica premier de la mejor calidad.</span> <br>
                                <span @click="redirect('category_jamaica')" class="pr--description poppins-bold text-success cursor-pointer">Conoce más ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row pt-5">
                    <div data-aos="fade-right" class="col-6 pl-5">
                        <img :src="section5_img5" class="img-fluid px-5">
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-start">
                        <div>
                            <div data-aos="fade-right" class="bg-success text-white poppins-black mt-4 mb-4" style="border-radius: 8px; padding: 8px;">
                                ENCHILADOS
                            </div>
                            <div data-aos="fade-right" data-aos-delay="400" class="text-left">
                                <span class="pr--description">Los mejores snacks para las mejores ocasiones.</span> <br>
                                <span @click="redirect('category_hot')" class="pr--description poppins-bold text-success cursor-pointer">Conoce más ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="row pt-5">
                    <div data-aos="fade-right" class="col-6">
                        <img :src="section5_img6" class="img-fluid pl-5">
                    </div>
                    <div class="col-6 d-flex justify-content-center align-items-start">
                        <div>
                            <div data-aos="fade-right" class="bg-success text-white poppins-black mt-4 mb-4" style="border-radius: 8px; padding: 8px;">
                                ESPECIAS
                            </div>
                            <div data-aos="fade-right" data-aos-delay="400" class="text-left">
                                <span class="pr--description">Conoce nuestra gran variedad de especias.</span> <br>
                                <span @click="redirect('category_spices')" class="pr--description poppins-bold text-success cursor-pointer">Conoce más ></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

  	</div>
</template>

<script>
	// Video
    import video_main from '../assets/video/home/video-main.mp4'
    import s2_video1 from '../assets/video/home/caso-exito-loop.mp4'
    import s3_video1 from '../assets/video/home/bg-productos-index.mp4'
    
    // Images
    import section1_img1 from '../assets/images/home/foto-desarrollo-humano.png'
    import section1_img2 from '../assets/images/home/foto-productos-de-calidad-mundial.png'
    import section1_img3 from '../assets/images/home/foto-gran-capacidad-instalada.png'

    import section2_img1 from '../assets/images/home/foto-50-años-experiencia.png'
    import section2_img2 from '../assets/images/home/foto-pistache-hojas.png'
    import section2_img3 from '../assets/images/home/almendras-premier.jpg'
    import img_logo from '../assets/images/home/logo-premier.png'

    import section3_img1 from '../assets/images/weare/productos-bolsas.png' //'../assets/images/home/productos.png'
    import section3_img2 from '../assets/images/home/foto_mujer.png'

    import section4_img1 from '../assets/images/home/foto-almendra-canasta.png'
    import section4_img2 from '../assets/images/home/foto-01-hover.png'
    import section4_img3 from '../assets/images/home/foto-02-hover.png'
    import section4_img4 from '../assets/images/home/foto-03-hover.png'
    import section4_img5 from '../assets/images/home/foto-04-hover.png'
    import img_logo_white from '../assets/images/home/logo-premier-blanco.png'
    import section4_img6 from '../assets/images/home/foto-fabrica.png'
    
    import section5_img1 from '../assets/images/home/productos/foto-pistache.png'
    import section5_img2 from '../assets/images/home/productos/foto-linaza.png'
    import section5_img3 from '../assets/images/home/productos/foto-kiwi.png'
    import section5_img4 from '../assets/images/home/productos/foto-jamaica.png'
    import section5_img5 from '../assets/images/home/productos/foto-mango.png'
    import section5_img6 from '../assets/images/home/productos/foto-quinoa.png'

    // Gifs
    import gif_premier_mixed from '../assets/images/home/logo-premier.png'
    
    // jQuery
    import $ from 'jquery'

	export default {
		name: 'pr_home',
		data() {
			return {
                video_main,
                section1_img1,
                section1_img2,
                section1_img3,
                section2_img1,
                section2_img2,
                section2_img3,
                img_logo,
                s2_video1,
                s3_video1,
                section3_img1,
                section3_img2,
                section4_img1,
                section4_img2,
                section4_img3,
                section4_img4,
                section4_img5,
                section4_img5,
                img_logo_white,
                section4_img6,
                section5_img1,
                section5_img2,
                section5_img3,
                section5_img4,
                section5_img5,
                section5_img6,
                gif_premier_mixed
			}
	    },
	    methods: {
            redirect(name) {
                window.scrollTo( 0, 0 )
                
                this.$router.push({
                    name: name
                })
            }
        },
        created() {
            $(window).resize(function() {
                let w = 0
                if($('#pr_video_main').width() > 888) {
                    w = ($('#pr_video_main').width() / 10) * 3
                }
                else {
                    w = ($('#pr_video_main').width() / 10) * 5
                }
                
                $("#overlay_main_video").css("width", w)
                let position_left = 0 
                $("#overlay_main_video").css("left", position_left)
                let position_top = (($('#pr_video_main').height() / 3) * 2)
                $("#overlay_main_video").css("top", position_top)


                // Image play
                let height_s3_video_1 = $('#s3_video_1').height()
                $("#s3_div_cont_target_1").css("height", height_s3_video_1)
                $("#s3_div_row_target_1").css("height", height_s3_video_1)

            })
        },
        mounted() {
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 200);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 600);
            setTimeout(function() { 
                $(window).trigger('resize'); 
            }, 1000);
        }
	}
</script>

<style scoped lang="scss">
	#pr_home {
		min-height: 1000px !important;

        .img_black_white {
            -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
            filter: grayscale(100%);
            -webkit-transition: all 0.3s;
            -o-transition: all 0.3s;
        }

        .img_black_white:hover {
            -webkit-filter: grayscale(0%); /* Safari 6.0 - 9.0 */
            filter: grayscale(0%);
        }
	}
</style>