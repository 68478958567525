<template>
    <div id="app">
        <pr-navbar></pr-navbar>
        <router-view/>
        <pr-footer></pr-footer>
    </div>
</template>

<script>
    // Components
    import Navbar from './components/pr-navbar.vue'
    import Footer from './components/pr-footer.vue'

    export default {
        name: 'app_main',
        components: {
            'pr-navbar': Navbar,
            'pr-footer': Footer
        }
    }
</script>

<style lang="scss">
    #app {
        font-family: Lato-Regular;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100% !important;
        text-align: center;
    }
</style>
