<template>
    <div id="pr-navbar">
        <!-- Line primary -->
        <div id="pr_line_primary" class="line-primary">
            <div>
                <img :src="img_cell">
                <span class="ml-2 mr-3 mr-lg-5">+52 (33) 8000 8300</span>
                
                <img :src="img_mail">
                <span class="ml-2 mr-3 mr-lg-5">info@gpopremier.com</span>
            </div>
            <div>
                <a href="https://www.facebook.com/premierdeoccidente" target="_blank"><img :src="img_fb" class="mx-1"></a>
                <a href="https://twitter.com/PremierGrupo" target="_blank"><img :src="img_tw" class="mx-1"></a>
                <a href="https://www.instagram.com/gpopremier" target="_blank"><img :src="img_in" class="mx-1"></a>
            </div>
        </div>

        <!-- Navbar -->
        <b-navbar id="pr_navbar" toggleable="lg" type="light" variant="white" class="fixed-top-custom pl-sm-5 py-1">
            <b-navbar-brand id="pr_navbar__title" @click="redirect('home')" class="d-block d-lg-none d-xl-block  mr-3 mr-lg-5" style="max-width: 120px; cursor: pointer;">
                <img id="pr_logo_white" :src="gif_premier_color" class="img-fluid">
            </b-navbar-brand>

            <b-collapse id="nav-text-collapse" is-nav>

                <b-navbar-nav @click="redirect('home')" @mouseover="hover_home = true" @mouseleave="hover_home = false" class="cursor-pointer px-lg-3 px-xl-4">
                    <b-nav-text class="all_transition_slow" :class="(current_route_name == 'home') ? 'text-focus' : ''">
                        Inicio
                    </b-nav-text>

                    <!-- <b-dd-form class="my-dd bg-primary position-absolute mt-4 ml-5 border rounded-bottom rounded-right" v-show="hover_home">
                        <b-dd-item @click.stop="redirectToSamePage('home', 'success-story')" class="bg-primary">
                            <span class="pr--description text-white">Una historia de éxito</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirectToSamePage('home', 'our-products')" class="bg-primary">
                            <span class="pr--description text-white">Nuestros productos</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirectToSamePage('home', 'strong-relationship')" class="bg-primary">
                            <span class="pr--description text-white">Sólida relación con nuestros socios</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirectToSamePage('home', 'product-line')" class="bg-primary">
                            <span class="pr--description text-white">Conoce nuestra línea de productos</span>
                        </b-dd-item>
                    </b-dd-form> -->
                </b-navbar-nav>

                <b-navbar-nav @click="redirect('weare')" @mouseover="hover_weare = true" @mouseleave="hover_weare = false" class="cursor-pointer px-lg-3 px-xl-4">
                    <b-nav-text class="all_transition_slow" :class="(current_route_name == 'weare') ? 'text-focus' : ''">
                        Somos
                    </b-nav-text>

                    <!-- <b-dd-form class="my-dd bg-primary position-absolute mt-4 ml-5 border rounded-bottom rounded-right" v-show="hover_weare">
                        <b-dd-item @click.stop="redirectToSamePage('weare', 'we-are')" class="bg-primary">
                            <span class="pr--description text-white">Quienes somos</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirectToSamePage('weare', 'our-products')" class="bg-primary">
                            <span class="pr--description text-white">Nuestros productos</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirectToSamePage('weare', 'our-history')" class="bg-primary">
                            <span class="pr--description text-white">Nuestra historia</span>
                        </b-dd-item>
                    </b-dd-form> -->
                </b-navbar-nav>

                <b-navbar-nav @click="redirect('commitment')" @mouseover="hover_commitment = true" @mouseleave="hover_commitment = false" class="cursor-pointer px-lg-3 px-xl-4">
                    <b-nav-text class="all_transition_slow" :class="(current_route_name == 'commitment') ? 'text-focus' : ''">
                        Compromiso
                    </b-nav-text>

                    <!-- <b-dd-form class="my-dd bg-primary position-absolute mt-4 ml-5 border rounded-bottom rounded-right" v-show="hover_commitment">
                        <b-dd-item @click.stop="redirectToSamePage('commitment', 'our-commitment')" class="bg-primary">
                            <span class="pr--description text-white">Nuestro compromiso</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirectToSamePage('commitment', 'our-family')" class="bg-primary">
                            <span class="pr--description text-white">Familia de socios</span>
                        </b-dd-item>
                    </b-dd-form> -->
                </b-navbar-nav>

                <b-navbar-nav @click="redirect('products')" @mouseover="hover_products = true" @mouseleave="hover_products = false" class="cursor-pointer px-lg-3 px-xl-4">
                    <b-nav-text class="all_transition_slow" :class="(current_route_name == 'products') ? 'text-focus' : ''">
                        Productos
                    </b-nav-text>

                    <!-- <b-dd-form class="my-dd bg-primary position-absolute mt-4 ml-5 border rounded-bottom rounded-right" v-show="hover_products">
                        <b-dd-item @click.stop="redirect('category_nuts')" class="bg-primary">
                            <span class="pr--description text-white">Frutos secos</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirect('category_dehydrated')" class="bg-primary">
                            <span class="pr--description text-white">Frutos deshidratados</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirect('category_hot')" class="bg-primary">
                            <span class="pr--description text-white">Enchilados</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirect('category_super')" class="bg-primary">
                            <span class="pr--description text-white">Super foods</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirect('category_jamaica')" class="bg-primary">
                            <span class="pr--description text-white">Jamaica</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirect('category_spices')" class="bg-primary">
                            <span class="pr--description text-white">Especias</span>
                        </b-dd-item>
                    </b-dd-form> -->
                </b-navbar-nav>

                <!-- <b-navbar-nav @mouseover="hover_clients = true" @mouseleave="hover_clients = false" class="cursor-pointer px-lg-3 px-xl-4">
                    <b-nav-text class="all_transition_slow" :class="(current_route_name == 'ocean_spray') ? 'text-focus' : ''">
                        Nuestras Marcas
                    </b-nav-text>

                    <b-dd-form class="my-dd bg-primary position-absolute mt-4 ml-5 border rounded-bottom rounded-right" v-show="hover_clients">
                        <b-dd-item  @click="redirect('ocean_spray')" class="bg-primary">
                            <span class="pr--description text-white">Ocean Spray</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click="redirectToPage('http://premiernuts.mx/')" class="bg-primary">
                            <span class="pr--description text-white">Premier Nuts</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click="redirectToPage('https://mercadopremier.com/')" class="bg-primary">
                            <span class="pr--description text-white">Mercado Premier</span>
                        </b-dd-item>
                    </b-dd-form>
                </b-navbar-nav> -->

                <b-navbar-nav @click="redirect('self_services')" class="cursor-pointer px-lg-3 px-xl-4">
                    <b-nav-text class="all_transition_slow" :class="(current_route_name == 'self_services') ? 'text-focus' : ''">
                        Autoservicios
                    </b-nav-text>
                </b-navbar-nav>

                <b-navbar-nav @click="redirect('infrastructure')" @mouseover="hover_infrastructure = true" @mouseleave="hover_infrastructure = false" 
                    class="cursor-pointer px-lg-3 px-xl-4">
                    <b-nav-text class="all_transition_slow" :class="(current_route_name == 'infrastructure') ? 'text-focus' : ''">
                        Infraestructura
                    </b-nav-text>

                    <!-- <b-dd-form class="my-dd bg-primary position-absolute mt-4 ml-5 border rounded-bottom rounded-right" v-show="hover_infrastructure">
                        <b-dd-item @click.stop="redirectToSamePage('infrastructure', 'pr_group1_img')" class="bg-primary">
                            <span class="pr--description text-white">Guadalajara</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirectToSamePage('infrastructure', 'pr_group2_img')" class="bg-primary">
                            <span class="pr--description text-white">Ciudad de México</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirectToSamePage('infrastructure', 'pr_group3_img')" class="bg-primary">
                            <span class="pr--description text-white">Monterrey</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirectToSamePage('infrastructure', 'pr_group4_img')" class="bg-primary">
                            <span class="pr--description text-white">Planta Procesadora</span>
                        </b-dd-item>
                        <hr class="m-0">
                        <b-dd-item @click.stop="redirectToSamePage('infrastructure', 'pr_group5_img')" class="bg-primary">
                            <span class="pr--description text-white">Punto de venta</span>
                        </b-dd-item>
                    </b-dd-form> -->
                </b-navbar-nav>

                <b-navbar-nav @click="redirect('contact')" class="cursor-pointer px-lg-3 px-xl-4">
                    <b-nav-text class="all_transition_slow" :class="(current_route_name == 'contact') ? 'text-focus' : ''">
                        Contacto
                    </b-nav-text>
                </b-navbar-nav>

                <b-navbar-nav @click="redirectToPage('https://mercadopremier.com/')" class="cursor-pointer px-lg-3 px-xl-4">
                    <b-nav-text class="all_transition_slow">
                        <img :src="img_mercado">
                    </b-nav-text>
                </b-navbar-nav>

                <b-navbar-nav @click="redirectToPage('https://premiernuts.com.mx')" class="cursor-pointer px-lg-3 px-xl-4">
                    <b-nav-text class="all_transition_slow">
                        <img :src="img_nuts">
                    </b-nav-text>
                </b-navbar-nav>

                <b-navbar-nav @click="redirect('ocean_spray')" class="cursor-pointer px-lg-3 px-xl-4">
                    <b-nav-text class="all_transition_slow" :class="(current_route_name == 'ocean_spray') ? 'text-focus' : ''">
                        <img :src="img_ocean">
                    </b-nav-text>
                </b-navbar-nav>
            </b-collapse>

            <b-button v-b-toggle.sidebar-right variant="link">
                <b-icon id="pr_navbar__menu_icon" icon="list" scale="2" variant="secondary"></b-icon>
            </b-button>
        </b-navbar>

        <!-- Sidebar -->
        <b-sidebar id="sidebar-right" bg-variant="primary" class="pr_sidebar" right backdrop shadow>
            <div class="pr_item border-top border-bottom text-black py-3">
                <span @click="redirect('home')"> INICIO </span>
            </div>
            <div class="pr_item border-bottom py-3">
                <span @click="redirect('weare')"> SOMOS </span>
            </div>
            <div class="pr_item border-bottom py-3">
                <span @click="redirect('commitment')"> COMPROMISO </span>
            </div>
            <div class="pr_item border-bottom py-3">
                <span @click="redirect('products')"> PRODUCTOS </span>
            </div>
            <div class="pr_item border-bottom py-3">
                <span @click="redirect('ocean_spray')"> OCEAN SPRAY </span>
            </div>
            <div class="pr_item border-bottom py-3">
                <span @click="redirect('self_services')"> AUTOSERVICIOS </span>
            </div>
            <div class="pr_item border-bottom py-3">
                <span @click="redirect('infrastructure')"> INFRAESTRUCTURA </span>
            </div>
            <div class="pr_item border-bottom py-3">
                <span @click="redirect('contact')"> CONTACTO </span>
            </div>

            <div class="row pt-5">
                <div class="col-12 p-5">
                    <div style="max-width: 120px;">
                        <img :src="gif_premier_mixed" class="img-fluid mb-3">    
                    </div>
                    <span class="pr-description-sm text-white">
                        © 2023 Grupo Premier. Todos los derechos reservados
                    </span>
                </div>
            </div>
        </b-sidebar>
    </div>
</template>

<script>
    // Images
    import img_cell from '../assets/images/navbar/cellphone.png'
    import img_mail from '../assets/images/navbar/mail.png'
    import img_fb from '../assets/images/navbar/facebook.png'
    import img_tw from '../assets/images/navbar/twitter.png'
    import img_in from '../assets/images/navbar/instagram.png'

    import img_mercado from '../assets/images/our_brands/mercado.png'
    import img_nuts from '../assets/images/our_brands/nuts.png'
    import img_ocean from '../assets/images/our_brands/ocean-spray.png'
    // Gifs
    import gif_premier_color from '../assets/images/home/logo-premier-colores.png'
    import gif_premier_white from '../assets/images/home/logo-premier-blanco.png'
    import gif_premier_mixed from '../assets/images/home/logo-premier.png'
    // jQuery
    import $ from 'jquery'

    export default {
        name: 'pr-navbar',
        props: {
            msg: String
        },
        data() {
            return {
                img_cell,
                img_mail,
                img_fb,
                img_tw,
                img_in,
                img_mercado,
                img_nuts,
                img_ocean,
                gif_premier_color,
                gif_premier_white,
                gif_premier_mixed, 

                hover_home: false, 
                hover_weare: false, 
                hover_commitment: false, 
                hover_products: false, 
                hover_clients: false, 
                hover_infrastructure: false
            }
        },
        computed: {
            current_route_name() {
                return this.$route.name;
            }
        },
        methods: {
            redirect(name_route) {
                //console.log('redirect')
                if(this.$route.name !== name_route ) {
                    window.scrollTo(
                        0, 0)

                    this.$router.push({
                        name: name_route
                    })
                }
            },
            redirectToSamePageSimple(name_route, element_id) {
                //console.log('redirectToSamePageSimple')
                document.getElementById(element_id).scrollIntoView({ 
                    behavior: 'smooth', 
                    block: 'start', 
                    inline: 'start' 
                })
            },
            redirectToSamePage(name_route, element_id) {
                //console.log('redirectToSamePage')
                if(this.$route.name !== name_route) {
                    this.redirect(name_route)    
                }

                setTimeout(function() {
                    window.scrollTo(0, 0)
                    const top = document.getElementById(element_id).getBoundingClientRect().top
                    const height_navbar = $('#pr-navbar').height()
                    const y = (top - window.pageYOffset) - height_navbar - 40;

                    if(y >= 0) {
                        window.scrollTo({ top: y, behavior: 'smooth' });
                    }    
                }, 300);
            },
            redirectToPage(url, target = "_blank") {
                if(target == "_blank") {
                    window.open(url, '_blank')
                }
                else {
                    window.location.replace(url)
                }
            }

        },
        created() {
            $(window).scroll(function() {
                //console.log(`scroll: ${$(document).scrollTop()}`)

                if($(document).scrollTop() > ($('#pr_line_primary').height() + 20)) {
                    $('#pr_navbar').addClass('pr_navbar__scroll');
                    $('#pr_navbar').addClass('fixed-top');
                    $('#pr_navbar').removeClass('fixed-top-custom');
                    // $('#pr_navbar').removeClass('bg-transparent');
                    // $('#pr_logo_white').addClass('d-none');
                    // $('#pr_logo_white').removeClass('d-flex');
                    // $('#pr_logo_gray').addClass('d-flex');
                    // $('#pr_logo_gray').removeClass('d-none');
                    // $('#pr_navbar__title').addClass('text-black');
                    // $('#pr_navbar__title').removeClass('text-white');
                    // $('#pr_navbar__menu_icon').addClass('text-black');
                    // $('#pr_navbar__menu_icon').removeClass('text-white');
                }
                else {
                    // $('#pr_navbar').addClass('bg-transparent');
                    $('#pr_navbar').removeClass('pr_navbar__scroll');
                    $('#pr_navbar').addClass('fixed-top-custom');
                    $('#pr_navbar').removeClass('fixed-top');
                    // $('#pr_logo_white').addClass('d-flex');
                    // $('#pr_logo_white').removeClass('d-none');
                    // $('#pr_logo_gray').addClass('d-none');
                    // $('#pr_logo_gray').removeClass('d-flex');
                    // $('#pr_navbar__title').addClass('text-white');
                    // $('#pr_navbar__title').removeClass('text-black');
                    // $('#pr_navbar__menu_icon').addClass('text-white');
                    // $('#pr_navbar__menu_icon').removeClass('text-black');
                }
            })
        }
    }
</script>

<style lang="scss">

    #pr_navbar {
        -webkit-transition: all 0.6s ease;
        transition: all 0.6s ease;
    }

    .pr_navbar__scroll {
        background-color: white;
        box-shadow: 1px 4px 26px 5px rgba(0,0,0,0.71);
        -webkit-box-shadow: 1px 4px 26px 5px rgba(0,0,0,0.71);
        -moz-box-shadow: 1px 4px 26px 5px rgba(0,0,0,0.71);
    }

    .pr_sidebar {

        .pr_item {
            cursor: default;

            span {
                font-family: 'Poppins-Black';
                color: white !important;
            }
            span:hover {
                text-decoration: underline;
                text-decoration-style: double;
            }
        }
    }

    .b-sidebar {
        width: 400px !important;

        .b-sidebar-header {

            button {
                color: white !important;

                svg {
                    font-size: 40px;
                }
            }
        }
    }

    .my-dd {
        .btn:focus, .btn:active {
            box-shadow: none !important;
        }

        a.dropdown-item {
            padding-top: 10px;
            padding-bottom: 10px;
        }

        a.dropdown-item:hover {
            span {
                -webkit-transition: all 0.5s;
                -o-transition: all 0.5s;
                transition: all 0.5s;
                color: black !important;
            }
        }

        .b-dropdown-form {
            padding: 0.25rem 0.5rem !important;
        }

        ul {
            background-color: #002554;
        }

        hr {
            background-color: white !important;
            height: 0px;
        }

    }
</style>
